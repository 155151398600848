.pagination-number {
  padding-inline: 12px;
  padding-block: 6px;
  border-radius: 4px;
}

.pagination-number.active {
  background-color: #06b681; /* Active background color */
  color: #fff; /* Text color for active page */
}

.expanded-row {
  border-style: solid;
  border-width: 2px 2px 0px 2px;
  border-color: #06b681;
  border-radius: 5px 5px 0px 0px;
}

.expanded-content {
  border-style: solid;
  border-width: 0px 2px 2px 2px;
  border-color: #06b681;
  border-radius: 0px 0px 5px 5px;
}

.header-cell {
  font-weight: bold;
  color: blue; /* Replace with your desired color code or name */
  font-size: 14px;
  /* Add any other styles you need */
}

.expanded-row {
  overflow: hidden;
}

.expanded-content {
  overflow: hidden;
  height: 0;
  transition: height 3s ease-in-out;
}

/* . {
  animation-duration: 3s;
} */

.animate__slideInDown {
  animation-name: slideInDown;
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}
.solid-dot-red {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 12px; /* Adjust the size of the solid dot */
  width: 12px; /* Adjust the size of the solid dot */
  background-color: #f94932; /* Set the color of the solid dot */
  border-radius: 50%;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}
