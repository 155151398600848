.pagination-number {
    padding-inline: 12px;
    padding-block: 6px;
    border-radius: 4px;
    
  }

  .pagination-number.active {
    background-color: #0079FF; /* Active background color */
    color: #fff; /* Text color for active page */
  }

  .expanded-row {
    border-style: solid;
    border-width: 2px 2px 0px 2px; 
    border-color: #0079FF;
    border-radius: 5px 5px 0px 0px;
  }
  
  .expanded-content {
    border-style: solid;
    border-width: 0px 2px 2px 2px; 
    border-color: #0079FF;
    border-radius: 0px 0px 5px 5px;
  }

  .header-cell {
    font-weight: bold;
    color: blue; 
    font-size: 14px;
    
  }

  .expanded-row {
    overflow: hidden;
  }
  
  .expanded-content {
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-in-out;
  }
  
  . {
    animation-duration: 0.3s; 
  }
  
  .animate__slideInDown {
    animation-name: slideInDown;
  }
  
  .animate__slideOutUp {
    animation-name: slideOutUp;
  }
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url('../../../../public/assets/icons/downarrow.svg');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 7px;
    color:#3B4472;
    font-weight: bold;
   
  }
  select:focus-visible {
   border: none;
   outline: none;
  }