.css-v6mo1y-MuiTimelineDot-root {
  margin: 0;
}

.custom-timeline-dot-green {
  position: relative;
  height: 12px;
  width: 12px;
}

.solid-dot-green {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 12px; /* Adjust the size of the solid dot */
  width: 12px; /* Adjust the size of the solid dot */
  background-color: #06b681; /* Set the color of the solid dot */
  border-radius: 50%;
}
.custom-timeline-dot-yellow {
  position: relative;
  height: 12px;
  width: 12px;
}

.solid-dot-yellow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 12px; /* Adjust the size of the solid dot */
  width: 12px; /* Adjust the size of the solid dot */
  background-color: #ffb846; /* Set the color of the solid dot */
  border-radius: 50%;
}
.custom-timeline-dot-red {
  position: relative;
  height: 12px;
  width: 12px;
}

.solid-dot-red {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 12px; /* Adjust the size of the solid dot */
  width: 12px; /* Adjust the size of the solid dot */
  background-color: #f94932; /* Set the color of the solid dot */
  border-radius: 50%;
}
.custom-timeline-dot-green::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background-color: #06b681;
  border-radius: 50%;
  animation: ripple 1.5s infinite; /* Apply the ripple animation */
}
.custom-timeline-dot-yellow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background-color: #ffb846;
  border-radius: 50%;
  animation: ripple 1.5s infinite; /* Apply the ripple animation */
}
.custom-timeline-dot-red::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background-color: #f94932;
  border-radius: 50%;
  animation: ripple 1.5s infinite; /* Apply the ripple animation */
}
@keyframes ripple {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #06b681 !important;
}
.css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #06b681 !important;
}
.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
  padding: 0px 16px !important;
  min-height: 75px !important;
}
